<template>
  <div class="mycenter">
    <header-content></header-content>
    <div id="website_about_wrap" style="width: 100%; overflow: hidden">
      <!-- banner -->
      <div class="banner">
        <div id="about_banner">
          <a>
            <span class="banner_img" style=""></span>
            <div class="banner_main">
              <img src="../assets/img/avatar.png" class="header-logo" />
              <div class="username">{{ userInfo.username }}</div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="contt">
        <el-tabs tab-position="left" style="height: 400px">
          <el-tab-pane label="账号安全">
            <div v-if="activeShow === ''">
              <div class="des">
                <div class="left">
                  <img src="../assets/img/mycenter01.png" class="header-logo" />
                </div>
                <div class="middle">
                  <h5>修改密码</h5>
                  <p>当前账号存在风险时可以修改密码</p>
                </div>
                <div class="right">
                  <el-button @click="showForm('editPass')">修改密码</el-button>
                </div>
              </div>
              <div class="des">
                <div class="left">
                  <img src="../assets/img/mycenter02.png" class="header-logo" />
                </div>
                <div class="middle">
                  <h5>短信重置密码</h5>
                  <p>当前账号存在风险时,可以获取短信重置密码</p>
                </div>
                <div class="right">
                  <el-button @click="showForm('resetPass')">重置密码</el-button>
                </div>
              </div>
              <div class="des">
                <div class="left">
                  <img src="../assets/img/mycenter03.png" class="header-logo" />
                </div>
                <div class="middle">
                  <h5>更换手机号</h5>
                  <p>用于密码修改时短信提示和密码遗失时重置密码</p>
                </div>
                <div class="right">
                  <el-button @click="showForm('replacePhone')"
                    >换手机号</el-button
                  >
                </div>
              </div>
            </div>
            <div v-else-if="activeShow === 'editPass'">
              <h4 class="title">修改密码</h4>
              <el-form
                ref="editPassForm"
                :model="editPassForm"
                :rules="editPassRules"
                label-width="0"
              >
                <el-form-item label="" prop="password">
                  <el-input
                    v-model="editPassForm.password"
                    show-password
                    placeholder="请输入原密码"
                  ></el-input>
                </el-form-item>
                <el-form-item label="" prop="newpassword">
                  <el-input
                    v-model="editPassForm.newpassword"
                    show-password
                    placeholder="请输入新密码"
                  ></el-input>
                </el-form-item>
                <el-form-item label="" prop="pass2">
                  <el-input
                    v-model="editPassForm.pass2"
                    show-password
                    placeholder="请再次输入新密码"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="editPassSubmit()"
                    >确定提交</el-button
                  >
                  <el-button @click="showForm('')">取消</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div v-else-if="activeShow === 'resetPass'">
              <h4 class="title">短信重置密码</h4>
              <el-form
                ref="ResetPassForm"
                :model="ResetPassForm"
                :rules="ResetPassRules"
                label-width="0"
              >
                <el-form-item label="" prop="username">
                  <el-input
                    v-model="ResetPassForm.username"
                    placeholder="请输入用户名"
                  ></el-input>
                </el-form-item>
                <el-form-item label="" prop="code">
                  <el-input
                    v-model="ResetPassForm.code"
                    placeholder="请输入验证码"
                  >
                    <template #append
                      ><el-button
                        :disabled="disabledResetPass"
                        @click="sendcodeResetPass"
                        >{{ btntxtResetPass }}</el-button
                      ></template
                    >
                  </el-input>
                </el-form-item>
                <el-form-item label="" prop="password">
                  <el-input
                    v-model="ResetPassForm.password"
                    show-password
                    placeholder="请输入密码"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="ResetPassSubmit"
                    >确定提交</el-button
                  >
                  <el-button @click="showForm('')">取消</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div v-else-if="activeShow === 'replacePhone'">
              <h4 class="title">更换手机号</h4>
              <el-form
                ref="replacePhoneForm"
                :model="replacePhoneForm"
                :rules="replacePhoneRules"
                label-width="0"
              >
                <el-form-item label="" prop="phone">
                  <el-input
                    v-model="replacePhoneForm.phone"
                    placeholder="请输入新手机号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="" prop="nowcode">
                  <el-input
                    v-model="replacePhoneForm.nowcode"
                    placeholder="请输入新手机号验证码"
                  >
                    <template #append
                      ><el-button
                        :disabled="disabledNew"
                        @click="sendcodeNew"
                        >{{ btntxtNew }}</el-button
                      ></template
                    >
                  </el-input>
                </el-form-item>
                <el-form-item label="" prop="code">
                  <el-input
                    v-model="replacePhoneForm.code"
                    placeholder="请输入旧手机号验证码"
                  >
                    <template #append
                      ><el-button
                        :disabled="disabledOld"
                        @click="sendcodeOld"
                        >{{ btntxtOld }}</el-button
                      ></template
                    >
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="replacePhoneSubmit"
                    >确定提交</el-button
                  >
                  <el-button @click="showForm('')">取消</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="我的资料">
            <div class="info">
              <div class="info-row">
                <span class="lable">昵称: </span>
                <span class="text">{{ userInfo.nick || "未填写" }}</span>
              </div>
              <div class="info-row">
                <span class="lable">用户名: </span>
                <span class="text">{{ userInfo.username || "未填写" }}</span>
              </div>
              <div class="info-row">
                <span class="lable">真实姓名: </span>
                <span class="text">{{ userInfo.realname || "未填写" }}</span>
              </div>
              <div class="info-row">
                <span class="lable">手机号: </span>
                <span class="text">{{ userInfo.phone || "未填写" }}</span>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <footer-content></footer-content>
  </div>
</template>

<script>
import HeaderContent from "@/components/HeaderContent.vue";
import FooterContent from "@/components/FooterContent.vue";
import axios from "@/utils/axios";
import { ElMessage } from "element-plus";

export default {
  name: "Mycenter",
  components: {
    HeaderContent,
    FooterContent
  },
  data() {
    /* 重复密码校验 */
    const equalPwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请确认密码"));
      } else {
        if (value !== this.editPassForm.newpassword) {
          callback(new Error("两次密码不同"));
        }
        callback();
      }
    };
    return {
      userInfo: {},
      editPassForm: {
        username: "",
        password: "",
        newpassword: "",
        pass2: "",
      },
      editPassRules: {
        password: {
          required: true,
          message: "请输入以字母开头8到16位原密码",
          trigger: "blur",
          pattern: /^(?=.*?[a-zA-Z])(?=.*?[0-9])[a-zA-Z0-9]{6,16}$/,
        },
        newpassword: {
          required: true,
          message: "请输入以字母开头8到16位新密码",
          trigger: "blur",
          pattern: /^(?=.*?[a-zA-Z])(?=.*?[0-9])[a-zA-Z0-9]{6,16}$/,
        },
        pass2: {
          required: true,
          validator: equalPwd,
          trigger: "blur",
        },
      },
      disabledResetPass: false, // 通过用户名获取验证码
      timeResetPass: 60,
      btntxtResetPass: "发送验证码",
      ResetPassForm: {
        username: "",
        code: "",
        password: "",
      },
      ResetPassRules: {
        username: {
          required: true,
          message: "请输入账号",
          trigger: "blur",
          pattern: /^[a-zA-Z0-9_-]{4,16}$/,
        },
        code: {
          required: true,
          message: "请输入验证码",
          trigger: "blur",
          pattern: /^\d{4}|\d{6}$/,
        },
        password: {
          required: true,
          message: "请输以字母开头8到16位入密码",
          trigger: "blur",
          pattern: /^(?=.*?[a-zA-Z])(?=.*?[0-9])[a-zA-Z0-9]{6,16}$/,
        },
      },
      replacePhoneForm: {
        username: "",
        phone: "",
        nowcode: "",
        code: "",
      },
      replacePhoneRules: {
        phone: {
          required: true,
          message: "请输入正确的手机号",
          trigger: "blur",
          pattern: /^0?(13|14|15|17|18|19)[0-9]{9}$/,
        },
        nowcode: {
          required: true,
          message: "请输入新手机验证码",
          trigger: "blur",
          pattern: /^\d{4}|\d{6}$/,
        },
        code: {
          required: true,
          message: "请输入旧手机验证码",
          trigger: "blur",
          pattern: /^\d{4}|\d{6}$/,
        },
      },
      disabledNew: false, // 新手机 获取验证码
      timeNew: 60,
      btntxtNew: "发送验证码",
      disabledOld: false, // 新手机 获取验证码
      timeOld: 60,
      btntxtOld: "发送验证码",
      activeShow: "",
    };
  },
  created() {},
  mounted() {
    let user=window.sessionStorage.getItem("userinfo");
    if(!user){
      this.$router.push({name:'Home'})
    }else{
      this.userInfo = JSON.parse(user);
    }
    console.log("个人信息", user);
  },
  methods: {
    showForm(str) {
      this.activeShow = str;
      if (this.activeShow === "") {
        Object.keys(this.editPassForm).forEach((key) => {
          this.editPassForm[key] = "";
        });
        Object.keys(this.ResetPassForm).forEach((key) => {
          this.ResetPassForm[key] = "";
        });
        Object.keys(this.replacePhoneForm).forEach((key) => {
          this.replacePhoneForm[key] = "";
        });
      }
    },
    // 修改密码
    editPassSubmit() {
      this.$refs.editPassForm.validate((valid) => {
        if (valid) {
          // console.log('修改密码')
          this.editPassForm.username = this.userInfo.username;
          axios.post("/replace_password", this.editPassForm).then((res) => {
            if (res.code == 1) {
              ElMessage({
                message: res.msg,
                type: "success",
              });
              this.showForm('')
            } else if (res.code == 0) {
              ElMessage({
                message: res.msg,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 通过用户名获取验证码
    sendcodeResetPass() {
      if (/^[a-zA-Z0-9_-]{4,16}$/.test(this.ResetPassForm.username)) {
        axios
          .post("/user_code", { username: this.ResetPassForm.username })
          .then((res) => {
            if (res.code == 1) {
              this.timeResetPass = 60;
              this.timeFunResetPass();
              ElMessage({
                message: res.msg,
                type: "success",
              });
            } else if (res.code == 0) {
              ElMessage({
                message: res.msg,
                type: "error",
              });
            }
          });
      } else {
        ElMessage({
          message: "请输入4到16位账号",
          type: "warning",
        });
      }
    },
    //发送手机验证码倒计时
    timeFunResetPass() {
      if (this.timeResetPass > 0) {
        this.disabledResetPass = true;
        this.timeResetPass--;
        this.btntxtResetPass = this.timeResetPass + "秒";
        setTimeout(this.timeFunResetPass, 1000);
      } else {
        this.timeResetPass = 0;
        this.btntxtResetPass = "发送验证码";
        this.disabledResetPass = false;
      }
    },
    // 短信重置密码提交
    ResetPassSubmit() {
      this.$refs.ResetPassForm.validate((valid) => {
        if (valid) {
          axios.post("/code_reset", this.ResetPassForm).then((res) => {
            if (res.code == 1) {
              ElMessage({
                message: "重置密码成功",
                type: "success",
              });
              this.showForm('')
              // window.sessionStorage.setItem(
              //   "username",
              //   JSON.stringify(res.username)
              // );
              // window.sessionStorage.setItem("token", JSON.stringify(res.token));
            } else if (res.code == 0) {
              ElMessage({
                message: res.msg,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 更换手机号提交
    replacePhoneSubmit() {
      this.$refs.replacePhoneForm.validate((valid) => {
        if (valid) {
          this.replacePhoneForm.username = this.userInfo.username;
          axios.post("/replace_phone", this.replacePhoneForm).then((res) => {
            if (res.code == 1) {
              ElMessage({
                message: "更换手机号成功",
                type: "success",
              });
              this.showForm('')
            } else if (res.code == 0) {
              ElMessage({
                message: res.msg,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 新手机 获取验证码
    sendcodeNew() {
      if (/^0?(13|14|15|17|18|19)[0-9]{9}$/.test(this.replacePhoneForm.phone)) {
        axios
          .post("/phone_code", { phone: this.replacePhoneForm.phone })
          .then((res) => {
            if (res.code == 1) {
              this.timeNew = 60;
              this.timeFunNew();
              ElMessage({
                message: res.msg,
                type: "success",
              });
            } else if (res.code == 0) {
              ElMessage({
                message: res.msg,
                type: "error",
              });
            }
          });
      } else {
        ElMessage({
          message: "请输入4到16位账号",
          type: "warning",
        });
      }
    },
    //发送手机验证码倒计时
    timeFunNew() {
      if (this.timeNew > 0) {
        this.disabledNew = true;
        this.timeNew--;
        this.btntxtNew = this.timeNew + "秒";
        setTimeout(this.timeFunNew, 1000);
      } else {
        this.timeNew = 0;
        this.btntxtNew = "发送验证码";
        this.disabledNew = false;
      }
    },
    // 旧手机 根据用户名 获取验证码
    sendcodeOld() {
      axios
        .post("/user_code", { username: this.userInfo.username })
        .then((res) => {
          if (res.code == 1) {
            this.timeOld = 60;
            this.timeFunOld();
            ElMessage({
              message: res.msg,
              type: "success",
            });
          } else if (res.code == 0) {
            ElMessage({
              message: res.msg,
              type: "error",
            });
          }
        });
    },
    //发送手机验证码倒计时
    timeFunOld() {
      if (this.timeNew > 0) {
        this.disabledOld = true;
        this.timeOld--;
        this.btntxtOld = this.timeOld + "秒";
        setTimeout(this.timeFunOld, 1000);
      } else {
        this.timeOld = 0;
        this.btntxtOld = "发送验证码";
        this.disabledOld = false;
      }
    },
  },
};
</script>
<style lang="scss" >
.mycenter {
  .box {
    .el-input__inner,
    .el-form-item__error {
      text-align: center;
      position: static;
    }
  }
  .el-tabs__item.is-left{
    font-size: 16px;
  }
}
</style>
<style lang="scss" scoped >
@import "../assets/css/index.css";

.mycenter {
  .banner_img {
    background: url('../assets/img/mycenter_bg.jpg')
      no-repeat center top;
  }
  .banner_main {
    .header-logo {
      padding-top: 50px;
    }
    .username {
      font-size: 16px;
      color: #fff;
      line-height: 50px;
    }
  }
  .box {
    width: 800px;
    margin: 0 auto;
    min-height: 600px;
    .contt {
      padding-top: 50px;
      .title {
        padding-left: 16px;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 16px;
      }
      .des {
        overflow: hidden;
        padding: 20px 30px;
        .left {
          float: left;
          img {
            display: block;
            width: 70px;
            height: 70px;
          }
        }
        .middle {
          float: left;
          text-align: left;
          margin-left: 30px;
          margin-top: 8px;
          h5 {
            color: #333;
            font-size: 18px;
          }
          p {
            color: #888;
            font-size: 16px;
          }
        }
        .right {
          float: right;
          margin-top: 20px;
        }
      }
      .info {
        .info-row {
          font-size: 16px;
          color: #666;
          line-height: 60px;
          overflow: hidden;
          .lable {
            float: left;
            width: 40%;
            text-align: right;
          }
          .text {
            width: 54%;
            float: right;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>